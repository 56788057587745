import React from "react";

import foto from './background.jpg'
import "./style.css";
const DressCode = () => {
  const backgroundImage = `url(${foto})`;
  return (
    <section id="dressCode" className="section-bg dress"  style={{ backgroundImage }}>
      <div className="container" data-aos="fade-up">
        {/* <div className="section-header">
                    <h2 className="section-subtitle">Confirma tu asistencia</h2>
                </div> */}

        <div className="row contact-info">
          <div className="col-md-6 dress-code">
            <div className="dress-code-sect">
              <i className="bi bi-geo-alt"></i>
              <img
                className="sink-on-hover"
                src="https://img.icons8.com/dotty/80/wedding-dress.png"
                alt="dressCode"
              />
              <br></br>
              <br></br>
              <h3>Dress Code</h3>
              <div>
                <p>
                  <strong>Riguroso Formal</strong>
                </p>
                <br></br>
                {/* <address>Etiqueta Rigurosa.</address> */}
                <strong>Hombres</strong>
                <br></br>
                <p>Traje completo, zapato formal.</p>
                <strong>Mujeres</strong>
                <br></br>
                <p>Vestido largo.</p>
                <div>
                  <br></br>
                  <p className="no-child">
                    <strong>NO NIÑOS</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-4">
                        <div className="contact-phone">
                            <i className="bi bi-phone"></i>
                            <h3>Tu confirmación es importante</h3>
                            <p>Da click en el siguiente botón para confirmar.</p>
                        </div>
                    </div> */}

          <div className="col-md-6 hashtag">
            <div className="hash-sect">
              <i className="bi bi-envelope"></i>
              <img
                className="sink-on-hover"
                src="https://img.icons8.com/ios/50/000000/instagram-new--v3.png"
                alt="hashtag"
              />
              <br></br>
              <br></br>
              <h3>COMPARTE MOMENTOS</h3>
              <p>
                <a href="https://www.instagram.com/?hl=es-la" target="{_blank}">
                  ¡Etiquétame en tus historias de instagram!
                  <br />
                  <br />
                </a>
              </p>
              <a href="https://www.instagram.com/?hl=es-la" target="{_blank}">
                <h3>@sophia_calmtz</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DressCode;
