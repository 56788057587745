import React from "react";

import "./style.css";

const BackToHome = () => {
  return (
    <div>
      <a href="/">
        <button
          className="button-to-home shrink-on-hover-button">
          <img className="home-icon"
            src="https://img.icons8.com/windows/32/000000/home.png"
            alt="home"
          />
        </button>
      </a>
    </div>
  );
};

export default BackToHome;
