import React from "react";
// import { useParams } from "react-router-dom";
import BackToTopButton from "./containers/BackToTop";

import Header from "./components/Header";
import Hero from "./components/Hero/Hero";
import Introduction from "./components/Introduction/Introduction";
import BiblicalPhrase from "./components/BiblicalPhrase/biblicalPhrase";
import Parents from "./components/Parents/parents";
import Itinerario from "./components/Itinerario/Itinerario";
import DressCode from "./components/DressCode/dressCode";
import CountDown from "./components/Countdown";
import Gallery from "./components/Gallery/Gallery";
import Mesa from "./components/Mesa/Mesa";
import Login from "./components/Login";
import Footer from "./components/Footer/Footer";
import Song from "./containers/SongMainInvitation/songMainInvitation";

const Main = () => {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Introduction />
      <BiblicalPhrase />
      <Parents />
      <Itinerario />
      <DressCode />
      <CountDown />
      <Gallery />
      <Mesa />
      <Login />
      <Footer />
      <Song />
      <BackToTopButton />
    </React.Fragment>
  );
};

export default Main;
