import React from "react";

import Song from "../../containers/SongSaveTheDate/songSavetheDate";
import Countdown from "../../containers/Countdown";
import BackToHome from "../../containers/BackToHome";
import foto from "./sophie_V1.jpg"


import "./style.css";

const SaveTheDate = () => {
  const backgroundImage = `url(${foto})`;
  return (
    <section id="SaveTheDate-background" className="section-bg" style={{ backgroundImage }}>
      <div className="text-video">
        <h1>Save the Date</h1>
        <h3>11 | 05 | 2024</h3>
        <br></br>
        <h2>Sophia</h2>
        <br></br>
        <h6 className="text-center">XV - This is a new year, a new beginning</h6>
        <div className="text-center" data-aos="zoom-in">
          <Countdown />
        </div>
      </div>
      <Song />
      <BackToHome/>
    </section>
  );
};

export default SaveTheDate;
