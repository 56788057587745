import React from "react";

import "./style.css";

const Parents = () => {
  return (
    <div>
      <section id="padrinos" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="text-center">
            <h2 className="section-subtitle">Con la bendición de</h2>
            {/* <p>Here is our event padrinos</p> */}
          </div>

          <ul
            className="nav nav-tabs"
            role="tablist"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                href="#padres-novia"
                role="tab"
                data-bs-toggle="tab"
              >
                Mis Padres
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#padres-novio"
                role="tab"
                data-bs-toggle="tab"
              >
                Mis Padrinos
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link"
                href="#padrinos-rings"
                role="tab"
                data-bs-toggle="tab"
              >
                Nuestros Padrinos
              </a>
            </li> */}
          </ul>

          <h3 className="sub-heading">
          Hay momentos inolvidables que se atesoran en el corazón para siempre, este es uno de ellos.
          </h3>
          {/* <p className="sub-heading">1 Tesalonicenses 3:12</p> */}

          <div
            className="tab-content row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* <!-- Schdule Day 1 --> */}
            <div
              role="tabpanel"
              className="col-lg-9 tab-pane fade show active"
              id="padres-novia"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/dotty/80/wedding-dress.png"
                      alt="mama novia"
                    />
                  </div>
                  <h4>Jennefer Nereida Martínez Mercado</h4>
                  <p>
                  Gracias mamá por tu amor, por tu valentía y acompañarme siempre. Te amo.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/ios/50/suit.png"
                      alt="papá novia"
                    />
                  </div>
                  <h4>Omar Antonio Calderón Hernández</h4>
                  <p>
                  Gracias papá por el amor que me das, por guiarme y querer lo mejor para mí. Te amo.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Schdule Day 1 --> */}

            {/* <!-- Schdule Day 2 --> */}
            <div
              role="tabpanel"
              className="col-lg-9  tab-pane fade"
              id="padres-novio"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/dotty/80/wedding-dress.png"
                      alt="mamá novio"
                    />
                  </div>
                  <h4>Sandra Paola Salazar Romo</h4>
                  <p>
                  Gracias Tía Pao, por todo tu cariño y apoyo desde siempre. Te quiero.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/ios/50/suit.png"
                      alt="papá novio"
                    />
                  </div>
                  <h4>Diego Antonio Calderón Hernández</h4>
                  <p>
                  Gracias Tío Diego por cuidarme y amarme antes de conocerme. Te quiero.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Schdule Day 2 --> */}

            {/* <!-- Schdule Day 3 --> */}
            {/* <div
              role="tabpanel"
              className="col-lg-9  tab-pane fade"
              id="padrinos-rings"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-others-maxicons/62/000000/external-dove-peace-others-maxicons-2.png"
                      alt="padrinos velaion"
                    />
                  </div>
                  <h4>Ana Bertha Hernández Velázquez & Juan Antonio Calderón Leaños</h4>
                  <p>
                    Nos reconforta saber que estarán presentes en nuestras vidas de una manera muy especial. Gracias por el aprecio 
                    y apoyo brindado incondicionalmente, nos complace ser los beneficiarios de su cariño.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/carbon-copy/100/undefined/wedding-rings.png"
                      alt="padrinos anillos"
                    />
                  </div>
                  <h4>Martha Olivia Gastélum Armenta & Manuel Guillermo Silva Hernández</h4>
                  <p>
                    Las palabras no pueden transmitir nuestra gratitud por estar presentes en nuestro matrimonio, 
                    les estamos profundamente agradecidos por ser una guía y apoyo importante en nuestra unión.
                  </p>
                </div>
              </div>
            </div> */}
            {/* <!-- End Schdule Day 2 --> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Parents;
