import React, { useEffect, useState } from "react";
import BackToHome from "../../containers/BackToHome";
import {
  collection,
  getDocs,
  query
} from "firebase/firestore";
import db from "../firebase.config";

const TotalInvitados = () => {
  const [guestsConfirmed, setGuestsConfirmed] = useState([]);
  const [guestsNotConfirmed, setGuestsNotConfirmed] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const invitationRef = collection(db, `invitation`);
      const invitationQuery = query(invitationRef);
      const invitationSnapshot = await getDocs(invitationQuery);
      let guestsConfirmedArr = [];
      let guestsNotConfirmedArr = [];
      if (!invitationSnapshot.empty) {
        for (const invitation of invitationSnapshot.docs) {
          const invitationId = invitation.id;
          const guessRef = collection(db, `invitation/${invitationId}/guess`);
          const guessQuery = query(guessRef);
          const guessSnapshot = await getDocs(guessQuery);
          if (!guessSnapshot.empty) {
            for (const guess of guessSnapshot.docs) {
              const guestData = guess.data();
              const guestId = guess.id;
              if (guestData.confirmed) {
                guestsConfirmedArr.push({
                  id: guestId,
                  name: guestData.name,
                  confirmed: 'true'
                });
              } else {
                guestsNotConfirmedArr.push({
                  id: guestId,
                  name: guestData.name,
                  confirmed: 'false'
                });
              }

              if (guestData.wards?.length > 0) {
                for (const ward of guestData.wards) {
                  if (ward.confirmed) {
                    guestsConfirmedArr.push({
                      id: guestId + '-' + ward.name.replace(' ', ''),
                      name: ward.name,
                      confirmed: 'true',
                      invitation_Id: invitationId
                    });
                  } else {
                    guestsNotConfirmedArr.push({
                      id: guestId + '-' + ward.name.replace(' ', ''),
                      name: ward.name,
                      confirmed: 'false'
                    });
                  }
                }
              }
            }
          }
        }
      }
      setGuestsConfirmed(guestsConfirmedArr);
      setGuestsNotConfirmed(guestsNotConfirmedArr);
    };

    getData().then(() => {
      console.log("done");
    });
  }, []);

  const confirmedContent = guestsConfirmed.map((post) =>
    <tr key={post.id}>
      <td>{post.name}</td>
      <td>{post.confirmed}</td>
      <td>{post.invitation_Id}</td>
    </tr>
  );

  const notConfirmedContent = guestsNotConfirmed.map((post) =>
    <tr key={post.id}>
      <td>{post.name}</td>
      <td>{post.confirmed}</td>
    </tr>
  );

  return (
    <div>
      <h1>Asistentes</h1>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th><strong>Nombre</strong></th>
            <th><strong>Asistencia</strong></th>
            <th><strong>Slug</strong></th>
          </tr>
        </thead>
        <tbody>
          {confirmedContent}
        </tbody>
        <tfoot>
          <tr>
            <td><strong>Total</strong></td>
            <td><strong>{guestsConfirmed.length}</strong></td>
          </tr>
        </tfoot>
      </table>

      <h1>NO Asistentes</h1>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th><strong>Nombre</strong></th>
            <th><strong>Asistencia</strong></th>
          </tr>
        </thead>
        <tbody>
          {notConfirmedContent}
        </tbody>
        <tfoot>
          <tr>
            <td><strong>Total</strong></td>
            <td><strong>{guestsNotConfirmed.length}</strong></td>
          </tr>
        </tfoot>
      </table>
      <BackToHome/>
    </div>
  );
}

export default TotalInvitados;
