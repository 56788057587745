import mama from "./1.png";
import papa from "./2.png";
import velacion from "./velacion.png";
import argollas from "./argollas.png";

const obj = {
  mama,
  papa,
  velacion,
  argollas,
};

export default obj;
