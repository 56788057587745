import logo from "./logo";
import hero from "./hero";
import introduction from "./introduction";
import biblicalPhrase from "./biblicalPhrase";
import mesa from "./mesa";
import countdown from "./countdown";
import itinerario from "./itinerario";
import parents from "./parents";

const obj = {
  logo,
  hero,
  introduction,
  biblicalPhrase,
  mesa,
  countdown,
  itinerario,
  parents,
};

export default obj;
