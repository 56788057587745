import React from "react";

import images from "../../assets/images";
import "./style.css";
// import foto from "./church.png";

const Itinerario = () => {
  const { coctel, recepcion } = images.itinerario;
  return (
    <section id="schedule">
      <h2 className="h2-itinerario section-subtitle" data-aos="zoom-in">
        Itinerario
      </h2>
      <div className="itinerario">
        <div className="cointainer-itinerario" data-aos="fade-right">
          <img
            className="img-itinerario sink-on-hover"
            src="https://img.icons8.com/dotty/80/000000/church.png"
            // src={foto}
            alt="img"
          />
          <h3>
            <span className="title-itinerario">Ceremonia</span>
          </h3>
          <span className="address-itinerario">
            <p>
              <b>Iglesia San Josemaría Escrivá de Balaguer</b>
            </p>
            <center>
            José María Escrivá 550, Colinas de San Javier, 44660 Guadalajara, Jal.
            </center>
          </span>
          <span className="time-itinerario">
            <b>17:00 HRS</b>
          </span>
          <a
            className="a-itinerario shrink-on-hover-button"
            href="https://maps.app.goo.gl/dSg5aLEnmBdnQpiz7"
            target="{_blank}"
            role="button"
          >
            <span className="map-button ">VER MAPA</span>
          </a>
        </div>

        <div className="cointainer-itinerario" data-aos="zoom-in-down">
          {/* <img className="img-itinerario" src="https://img.icons8.com/external-tulpahn-basic-outline-tulpahn/48/000000/external-wine-hokkaido-tulpahn-basic-outline-tulpahn.png" alt="img"/> */}
          <img
            className="img-itinerario sink-on-hover"
            src="https://img.icons8.com/carbon-copy/100/cocktail.png"
            alt="img"
          />
          <h3>
            <span className="title-itinerario">Recepción</span>
          </h3>
          <span className="address-itinerario">
            <p>
              <b>Sophía Eventos</b>
            </p>
            <center>
            Volcán Barcena 341, Panorámica de Huentitán, 44259 Guadalajara, Jal.
            </center>
          </span>
          <span className="time-itinerario">
            <b>19:00 HRS</b>
          </span>

          <a
            className="a-itinerario shrink-on-hover-button"
            href="https://maps.app.goo.gl/eBjDT5v3u3f27DAB6"
            target="{_blank}"
            role="button"
          >
            <span className="map-button">VER MAPA</span>
          </a>
        </div>

       
      </div>
    </section>
  );
};

export default Itinerario;
