import React from "react";

import Typewriter from "typewriter-effect";
import images from "../../assets/images";
import "./style.css";

const Introduction = () => {
  const {foto1} = images.introduction;
  return (
    <div
      id="couple"
      className="bride-groom clear section-padding bg-cream"
      data-scroll-index="1"
    >
      <div className="container" data-aos="fade-up">
        <div className="row box-introduction">
          <div
            className="col-md-12 text-center mb-30 animate-box"
            data-animate-effect="fadeInUp"
          >
            <h6 className="section-subtitle">It's Happening!</h6>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-animate-effect="fadeInLeft"
          >
            <div className="item mb-30">
              <div className="img">
                <img src={foto1} alt="" />
              </div>
              <div className="info valign">
                <div className="full-width">
                  <h6>Sophia Calderón</h6> <span>Blessed</span>
                  {/* <p>"Tú eres la respuesta a todas mis plegarias. Eres una canción, un sueño, 
                  un susurro, Te amo ahora mientras escribo esto, y te amo ahora mientras lees esto"</p>  */}
                </div>
              </div>
              <p className="introduction-phrase">Te doy gracias señor por este nuevo año de vida, que celebraré junto a mis padres y mis personas queridas</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
