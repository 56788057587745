import React, { useState, useEffect } from "react";

import "./countDown.css";

const CountDownComponent = () => {
  const [isExpired, setIsExpired] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    // const countDownDate = new Date("Oct 08, 2022 17:00:").getTime();
    const countDownDate = new Date("2024-05-11T22:17:00").getTime(); //YYYY-MM-DD
    const x = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const day = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hour = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const second = Math.floor((distance % (1000 * 60)) / 1000);
      if (days !== day) {
        setDays(day);
      }
      if (hours !== hour) {
        setHours(hour);
      }
      if (minutes !== minute) {
        setMinutes(minute);
      }
      if (seconds !== second) {
        setSeconds(second);
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setIsExpired(true);
      }
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, [days, hours, minutes, seconds]);

  return (
    <div className="remaining-time-component">
      {isExpired ? (
        <div className="es-hoy">
          ¡Es Hoy!
          <br />
          ¡Es Hoy!
        </div>
      ) : (
        <div id="clock-component">
          <div className="time-sec-component">
            <span className="title-component">{days}</span> Days{" "}
          </div>
          <div className="time-sec-component">
            <span className="title-component">{hours}</span> Hours{" "}
          </div>
          <div className="time-sec-component">
            <span className="title-component">{minutes}</span> Minutes{" "}
          </div>
          <div className="time-sec-component">
            <span className="title-component">{seconds}</span> Seconds{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountDownComponent;
