import React from "react";
// import Countdown from '../../containers/Countdown'
import CountDownComponent from "./countDown";

import images from "../../assets/images";
import "./style.css";

const CountDown = () => {
  const backgroundImage = `url(${images.countdown.countdown})`;

  return (
    <section id="countdown" style={{ backgroundImage }}>
      <div className="container text-center" data-aos="zoom-in">
        <div className="section-header">
          {/* <h2>Newsletter</h2> */}
          <h6>XV - This is a new year, a new beginning</h6>
          {/* <p>Rerum numquam illum recusandae quia mollitia consequatur.</p> */}

          {/* <img className="img-fluid" src={logo} alt="" /> */}
          <div>
            <CountDownComponent />
          </div>
        </div>

        {/* <form method="POST" action="#">
                    <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10 d-flex">
                    <input type="text" className="form-control" placeholder="Enter your Email" />
                    <button type="submit" className="ms-2">Subscribe</button>
                    </div>
                    </div>
                </form> */}
      </div>
    </section>
  );
};

export default CountDown;
