import React from "react";

import images from "../../assets/images";
import "./style.css";

const BiblicalPhrase = () => {
  const backgroundImage = `url(${images.biblicalPhrase.biblical})`;
  return (
    <section id="biblical" style={{ backgroundImage }}>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6">
            <h3>Salmo 16:11</h3>
            <p>
            Me has dado a conocer la senda de la vida; me llenarás de alegría en tu presencia, 
            y de dicha eterna a tu derecha.
            </p>
          </div>
          {/* <div className="col-lg-3">
                        <h3>Where</h3>
                        <p>Downtown Conference Center, New York</p>
                    </div> */}
          {/* <div className="col-lg-3">
                        <h3>When</h3>
                        <p>Monday to Wednesday<br></br>10-12 December</p>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default BiblicalPhrase;
